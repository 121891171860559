export default {
  site: "HireTruckers.com",
  primaryColor: "#059669",
  hireFormUrl: "/hire",
  applicationFormConsentMessage: `By providing my phone number and clicking the button above, I
  agree to theTerms & Conditions and Privacy Policy, provide my
  electronic signature, and consent to receive automated marketing
  calls or text messages (e.g., prerecorded and/or autodialed
  calls), regardless of any previous registration on any company,
  State or National Do Not Call list, and emails from Hire Truckers,
  Trucking Clicks, and Craddock Holdings, LLC. Consent is not a
  condition of any purchase. You may opt-out at any time. Message &
  data rates may apply.`,
};
