import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFeature, LazyGrid, LazyLandingPage, LazyLandingPageHero, LazyPage, LazyRichText, LazyUsxBenefits, LazyUsxBody, LazyUsxCallout, LazyUsxHero, LazyUsxLandingPageTemplate, LazyUsxRequirements, LazyUsxBenefitCard, LazyUsxLpForm, LazyUsxPayIcon, LazyUsxRoadIcon, LazyUsxSupportIcon, LazyBenefits, LazyHero, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Feature", LazyFeature],
["Grid", LazyGrid],
["LandingPage", LazyLandingPage],
["LandingPageHero", LazyLandingPageHero],
["Page", LazyPage],
["RichText", LazyRichText],
["UsxBenefits", LazyUsxBenefits],
["UsxBody", LazyUsxBody],
["UsxCallout", LazyUsxCallout],
["UsxHero", LazyUsxHero],
["UsxLandingPageTemplate", LazyUsxLandingPageTemplate],
["UsxRequirements", LazyUsxRequirements],
["UsxBenefitCard", LazyUsxBenefitCard],
["UsxLpForm", LazyUsxLpForm],
["UsxPayIcon", LazyUsxPayIcon],
["UsxRoadIcon", LazyUsxRoadIcon],
["UsxSupportIcon", LazyUsxSupportIcon],
["Benefits", LazyBenefits],
["Hero", LazyHero],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
