import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45if_45custom_45domain_45global from "/opt/build/repo/middleware/redirectIfCustomDomain.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45if_45custom_45domain_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/middleware/auth.ts"),
  "is-on-admin-team": () => import("/opt/build/repo/middleware/isOnAdminTeam.ts")
}