export default defineNuxtRouteMiddleware(async (to, _from) => {
  const pagesSupportedForCustomDomains = ["index", "unbranded-job"];

  const isCustomDomain = useIsCustomDomain();
  if (
    isCustomDomain.value &&
    !pagesSupportedForCustomDomains.includes(to.name?.toString() || "")
  ) {
    return navigateTo({
      path: "/",
      query: to.query,
    });
  }
});
